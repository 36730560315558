import React from 'react';
import { Card, Clickable } from '@warp-ds/react';
import AdImage from './AdImage';
import AdStatusPill from './AdStatusPill';
import { AdProps } from './interface';

import ExternalDataEntry from './ExternalDataEntry';

interface AdItemProps {
  ad: AdProps;
  href?: string;
  showExternalData: boolean;
  showUpdated: boolean;
  adTitleFallbackText: string;
  daysUntilExpireText?: string | null;
  lastUpdatedText?: string | null;
}

export default function Ad({
  ad,
  href = undefined,
  showExternalData = false,
  showUpdated = false,
  adTitleFallbackText,
  daysUntilExpireText = null,
  lastUpdatedText = null,
}: AdItemProps) {
  let externalDataElements = null;
  if (showExternalData) {
    externalDataElements = Object.entries(ad.externalData ?? {}).map(
      ([key, entry]) => {
        if (entry.value === '0') {
          return null;
        }
        switch (key) {
          case 'favorites':
            return (
              <ExternalDataEntry
                key="favorites"
                value={entry.value || ''}
                label={entry.label || ''}
                iconClass="favorite"
              />
            );
          case 'clicks':
            return (
              <ExternalDataEntry
                key="clicks"
                value={entry.value || ''}
                label={entry.label || ''}
                iconClass="click"
              />
            );
          default:
            return null;
        }
      },
    );
  }

  const clickable = !!href;
  const adTitle = ad.data.title ?? adTitleFallbackText;
  const styleNotClickable = {
    border: 'none',
    overflow: 'initial',
    cursor: 'default',
    backgroundColor: 'transparent',
  };
  const styleClickable = { border: 'none', overflow: 'initial' };
  const cardStyle = clickable ? styleClickable : styleNotClickable;
  return (
  // @ts-expect-error need to override style
    <Card flat className="mb-16 flex" style={cardStyle}>
      <div aria-owns={`title_${ad.id}`} />
      <div>
        <AdImage
          widthPx={75}
          heightPx={75}
          imageUrl={ad.data.image}
        />
      </div>
      <div
        className="pr-16 py-2 ml-12"
        style={{ flex: '1 1 0', minWidth: '0px' }}
      >
        <div className="text-xs s-text-subtle mt-2">
          <AdStatusPill display={ad.state.display} label={ad.state.label} />
          {daysUntilExpireText && (
            <div
              className="ml-8 text-xs inline-block"
              style={{ flex: '0 0 auto' }}
            >
              {daysUntilExpireText}
            </div>
          )}
          {showUpdated && lastUpdatedText && (
            <div
              className="ml-16 text-xs inline-block"
              data-testid="lastUpdated"
            >
              {lastUpdatedText}
            </div>
          )}
          {externalDataElements && (
            <div
              className="ml-16 inline-block"
              style={{ flex: '0 0 auto' }}
            >
              {externalDataElements}
            </div>
          )}
        </div>
        <h3 id={`title_${ad.id}`} className="mb-0">
          {clickable ? (
            <Clickable
              href={href}
              className="text-m mt-4"
              // @ts-expect-error need to override style
              style={{
                color: '#464646',
                display: 'block',
                maxWidth: '500px',
                textDecoration: 'none',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
              }}
            >
              {adTitle}
            </Clickable>
          ) : (
            <span className="text-m">{adTitle}</span>
          )}
        </h3>
        <div
          style={{
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
          }}
          className="text-s font-normal"
        >
          {ad.data.subtitle ?? ''}
        </div>
      </div>
    </Card>
  );
}
