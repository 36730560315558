import React from 'react';
import IconCursor16 from '@warp-ds/icons/react/cursor-16';
import IconHeart16 from '@warp-ds/icons/react/heart-16';

interface ExternalDataEntryProps {
  label: string;
  value: string;
  iconClass: string;
}

export default function ExternalDataEntry({
  label,
  value,
  iconClass,
}: ExternalDataEntryProps) {
  return (
    <span data-testid="externalDataEntry">
      <div className="inline-block ml-8">
        {iconClass === 'favorite' ? (
          <IconHeart16 style={{ position: 'relative', top: '2px' }} />
        ) : (
          <IconCursor16 style={{ position: 'relative', top: '2px' }} />
        )}
      </div>
      <span className="button__text sr-only">{label}</span>
      <div
        className="inline-block text-xs ml-4"
        style={{ position: 'relative', top: '-2px' }}
      >
        {value}
      </div>
    </span>
  );
}
