import React from 'react';
import IconLandscape56 from '@warp-ds/icons/react/landscape-56';

interface AdImageProps {
  imageUrl: string | undefined;
  widthPx: number;
  heightPx: number;
}

function getBaseUrl(location: Location): string {
  // TODO make based on environment-config instead of hostname
  // Hack to show image by env until we agree on API format
  switch (location.hostname) {
    case 'www.finn.no':
      return 'https://images.finncdn.no/';
    case 'dev.finn.no':
    case 'local.finn.no':
      return 'https://dev-images.finncdn.no/';
    case 'www.tori.fi':
      return 'https://img.tori.net/';
    case 'dev.tori.fi':
    case 'local.tori.fi':
    case 'toripg.rc.nmp.finn.no':
    case 'local.toripg.rc.nmp.finn.no':
      return 'https://images.beta.tori.fi/';
    case 'www.dba.dk':
      return 'https://images.dbastatic.dk/';
    case 'dev.dba.dk':
    case 'local.dba.dk':
      return 'https://dev.images.dbastatic.dk/';
    case 'dev.blocket.sv':
    case 'local.blocket.sv':
      return 'https://images.dev.blocketcdn.se/';
    default:
      return 'https://dev-images.finncdn.no/';
  }
}

function getImageStyle(
  url: string | undefined,
  widthPx: number,
  heightPx: number,
) {
  const imageStyle: { [index: string]: string } = {
    backgroundSize: 'contain',
    width: `${widthPx}px`,
    height: `${heightPx}px`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  };
  const baseUrl = getBaseUrl(window.location);
  imageStyle.backgroundImage = `url('${baseUrl}dynamic/480w/${url}')`;
  imageStyle.backgroundSize = 'cover';

  return imageStyle;
}

export default function AdImage({
  imageUrl,
  widthPx,
  heightPx,
}: AdImageProps) {
  if (imageUrl) {
    return (
      <div
        className="rounded-4 s-bg-subtle"
        style={getImageStyle(imageUrl, widthPx, heightPx)}
      />
    );
  }

  return (
    <div
      className="rounded-4 s-bg-subtle"
      style={{
        width: `${widthPx}px`, height: `${heightPx}px`, display: 'grid', placeItems: 'center',
      }}
    >
      <IconLandscape56 className="s-icon-subtle" />
    </div>
  );
}
